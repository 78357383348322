<template>
  <div class="page-404">
    <h1>404</h1>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.page-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
